<template>
  <div class="body">
    <div class="status__info-block block-wide">
      <div class="status__info-item" v-for="item in n_info" :key="item.name">
        <div class="status__info-title">{{ item.title }}</div>
        <div class="status__info-text">{{ item.value }}</div>
      </div>
    </div>
    <div class="status__table block-wide">
      <status-table
        :header_items="headers"
        :items="badges"
        @change_sorted="change_sorted"
      ></status-table>
    </div>
    <div class="status__title status__container">
      <span>{{ $t("status.log") }}</span>
      <span
        ><custom-btn
          class="button button_theme_white status__btn-download"
          @click="download_log"
        >
          <div>
            <img src="@/assets/download.svg" alt="" />
          </div>
          <div>{{ $t("status.log_download") }}</div>
        </custom-btn></span
      >
    </div>
    <div class="status__logs">
      <status-logs-table
        :header_items="log_headers"
        :items="logs"
        @change_sorted="change_sorted_logs"
      ></status-logs-table>
    </div>
  </div>
</template>

<script>
import StatusTable from "@/components/components/statusTable";
import StatusLogsTable from "@/components/components/statusLogsTable";
import moment from "moment";
import CustomBtn from "@/components/recurringComponents/customBtn";

export default {
  name: "status",
  components: { CustomBtn, StatusLogsTable, StatusTable },
  data() {
    return {
      headers: [
        {
          name: "badge_id",
          title: this.$i18n.t("status.headers.badge_id"),
          sorted: 1,
          enable: false,
        },
        {
          name: "uid",
          title: this.$i18n.t("status.headers.uid"),
          sorted: 0,
          enable: false,
        },
        {
          name: "badge_status",
          title: this.$i18n.t("status.headers.badge_status"),
          sorted: 0,
          enable: false,
        },
        {
          name: "auth_time",
          title: this.$i18n.t("status.headers.auth_time"),
          sorted: 0,
          enable: false,
        },
        {
          name: "n_a_time",
          title: this.$i18n.t("status.headers.n_a_time"),
          sorted: 0,
          enable: false,
        },
        {
          name: "active_time",
          title: this.$i18n.t("status.headers.active_time"),
          sorted: 0,
          enable: false,
        },
        {
          name: "total_time",
          title: this.$i18n.t("status.headers.total_time"),
          sorted: 0,
          enable: false,
        },
        {
          name: "fw_ver",
          title: this.$i18n.t("status.headers.fw_ver"),
          sorted: 0,
          enable: false,
        },
      ],
      log_headers: [
        {
          name: "date",
          title: this.$i18n.t("status.headers.date"),
          sorted: 1,
          enable: false,
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$socket.readyState === 1) {
        vm.$socket.sendObj({
          route: to.name,
          token: localStorage.getItem("access_token"),
        });
      }
    });
  },
  created() {
    this.$store.dispatch("load_hub_status", this.$route.params.id);
  },
  beforeDestroy() {
    this.$store.dispatch("delete_hub_status");
  },
  watch: {
    "$i18n.locale"() {
      for (let item of this.headers) {
        item.title = this.$t(`status.headers.${item.name}`);
      }
      // for (let item of this.info) {
      //   item.title = this.$t(`status.info.${item.name}`);
      // }
    },
  },
  computed: {
    hub() {
      return this.$store.getters.HUB_STATUS;
    },
    badge_status() {
      return this.$store.getters.BADGES_STATUS;
    },
    n_info() {
      return [
        {
          name: "customer",
          title: this.$t("status.info.customer"),
          value: this.hub?.company?.legal_name
            ? this.hub?.company?.legal_name
            : "-",
        },
        {
          name: "pos",
          title: this.$t("status.info.pos"),
          value: this.hub?.pos?.name ? this.hub?.pos?.name : "-",
        },
        {
          name: "comment",
          title: this.$t("status.info.comment"),
          value: this.hub?.comment ? this.hub?.comment : "-",
        },
        {
          name: "timezone",
          title: this.$t("status.info.timezone"),
          value: this.hub?.timezone ? `UTC${this.hub.timezone}` : "-",
        },
        {
          name: "hub_id",
          title: this.$t("status.info.hub_id"),
          value: this.hub?.hub_id ? this.hub?.hub_id : "-",
        },
        {
          name: "battery_level",
          title: this.$t("status.info.battery_level"),
          value: this.hub?.battery_ready_to_use
            ? this.hub?.battery_ready_to_use + "%"
            : "-",
        },
        {
          name: "upload_mode",
          title: this.$t("status.info.upload_mode"),
          value: this.hub?.upload_mode
            ? this.hub?.upload_mode == "scheduled"
              ? this.$t("status.info.postponed") + this.hub?.upload_time
              : this.$t("status.info.immediate")
            : "-",
        },
        {
          name: "recording_mode",
          title: this.$t("status.info.recording_mode"),
          value: this.hub?.recording_mode
            ? this.hub?.recording_mode == "button"
              ? this.$t("status.info.with_button_recording")
              : this.$t("status.info.continuous_recording")
            : "-",
        },

        {
          name: "motion",
          title: this.$t("status.info.motion"),
          value: this.hub?.motion_detection_state
            ? this.$t("status.info.yes")
            : this.$t("status.info.no"),
        },
        {
          name: "fw_ver",
          title: this.$t("status.info.fw_ver"),
          value: this.hub?.firmware ? this.hub?.firmware : "-",
        },
      ];
    },
    badges() {
      return this.badge_status.map((badge) => {
        let auth_time = "";
        if (badge.ts_auth != null) {
          auth_time =
            badge.ts_auth != 0
              ? moment.unix(Number(badge.ts_auth)).format("DD.MM.YY, HH:mm:ss ")
              : "-";
        }
        let translate_statuses = [];
        if (badge.badge_status?.includes("Recording")) {
          translate_statuses.push(this.$t("badge.status.recording"));
        }
        if (badge.badge_status?.includes("Charging")) {
          translate_statuses.push(this.$t("badge.status.charging"));
        }
        if (badge.badge_status?.includes("On-body")) {
          translate_statuses.push(this.$t("badge.status.on_body"));
        }
        if (badge.badge_status?.includes("Upload")) {
          translate_statuses.push(this.$t("badge.status.upload"));
        }
        if (badge.badge_status?.includes("Waiting")) {
          translate_statuses.push(this.$t("badge.status.waiting"));
        }
        if (badge.badge_status?.includes("Error")) {
          translate_statuses.push(this.$t("badge.status.error"));
        }
        if (badge.badge_status?.includes("Rebooted")) {
          translate_statuses.push(this.$t("badge.status.rebooted"));
        }
        if (badge.badge_status?.includes("Hub is offline")) {
          translate_statuses.push(this.$t("badge.status.offline"));
        }
        if (badge.badge_status?.includes("Application is offline")) {
          translate_statuses.push(this.$t("badge.status.application_offline"));
        }
        if (badge.badge_status?.includes("Not in range")) {
          translate_statuses.push(this.$t("badge.status.not_in_range"));
        }
        return {
          badge_id: badge.badge_id,
          uid: badge.employee_id,
          badge_status: translate_statuses.join(", "),
          auth_time: auth_time,

          n_a_time:
            badge.ts_na != null
              ? new Date(badge.ts_na * 1000).toISOString().substr(11, 8)
              : "",
          active_time:
            badge.ts_active != null
              ? new Date(badge.ts_active * 1000).toISOString().substr(11, 8)
              : "",
          total_time:
            badge.ts_total != null
              ? new Date(badge.ts_total * 1000).toISOString().substr(11, 8)
              : "",
          fw_ver: badge.badge_fw_ver,
        };
      });
    },
    logs() {
      return this.$store.getters.HUB_LOGS.map((log) => {
        let log_ts = "";
        if (log.ts != null) {
          log_ts =
            log.ts !== 0
              ? moment.unix(Number(log.ts)).format("DD.MM.YY, HH:mm:ss ")
              : "";
        }
        return {
          id: log.id,
          date: log_ts,
          description: log.event,
        };
      });
    },
  },
  methods: {
    change_sorted(item) {
      let change_item = this.headers.find((it) => it.name === item);
      let sort = change_item.sorted;

      this.headers.forEach((item) => (item.sorted = 0));

      if (sort !== 1) change_item.sorted = 1;
      else change_item.sorted = -1;

      if (change_item.sorted !== 0) {
        this.badges.sort((a, b) => {
          if (a[item] > b[item]) return change_item.sorted;
          if (a[item] === b[item]) return 0;
          if (a[item] < b[item]) return -change_item.sorted;
        });
      }
    },
    download_log() {
      const data = JSON.stringify(this.logs);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "status_log.log");
      document.body.appendChild(link);
      link.click();
    },
    change_sorted_logs(item) {
      let change_item = this.log_headers.find((it) => it.name === item);
      let sort = change_item.sorted;

      this.headers.forEach((item) => (item.sorted = 0));

      if (sort !== 1) change_item.sorted = 1;
      else change_item.sorted = -1;

      if (change_item.sorted !== 0) {
        this.logs.sort((a, b) => {
          if (a[item] > b[item]) return change_item.sorted;
          if (a[item] === b[item]) return 0;
          if (a[item] < b[item]) return -change_item.sorted;
        });
      }
    },
  },
};
</script>

<style scoped>
.status__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status__btn-download {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #214fff;
  font-size: 16px;
  border: none;
}

.status__btn-download:hover {
  background-color: unset;
}
</style>
